import React, { useState, useEffect } from 'react'
import BigNumberJS from 'bignumber.js'
import { useActiveWeb3React } from 'hooks'
import styled from 'styled-components'
import { useContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { CardBody, Button, Text } from '@pancakeswap-libs/uikit'
import { ethers } from 'ethers'
import { BottomGrouping, Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

import PageHeader from 'components/PageHeader'
import { AutoColumn } from 'components/Column'

import { migrationContractAddressByChain } from 'config'
import { useTokenContract } from 'hooks/useContract'

import { getBalanceString } from 'utils/formatBalance'

import { GHC_OLD_TOKEN_BY_CHAIN, GHC_NEW_TOKEN_BY_CHAIN } from '../../constants'
import * as MigrateAbi from './migrate-abi.json'

import AppBody from '../AppBody'

export const Btn = styled(Button)`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.textSubtle};
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-opacity: 0.5;
  }
  &:disabled {
    background-color: rgba(100, 100, 100, 0.6);
    color: rgb(30, 30, 30);
    cursor: none;
  }
`

const ErrorText = styled.div`
  text-align: center;
  color: red;
`

const BridgePage = () => {
  const { account, chainId } = useActiveWeb3React()
  const migrationContract = useContract(
    migrationContractAddressByChain[chainId || '56'],
    MigrateAbi?.abi,
    true
  )
  const [ghcOldBalance, setGhcOldBalance] = useState('0')
  const [ghcNewBalance, setGhcNewBalance] = useState('0')

  const [bridgeEnabled, setBridgeEnabled] = useState(false)

  const [pendingTx, setPendingTx] = useState(false)

  const [renderKey, setRenderKey] = useState('0')

  const { toastSuccess, toastError } = useToast()

  const [chainIdError, setChainIdError] = useState(false)

  const ghcTokenOld = GHC_OLD_TOKEN_BY_CHAIN[chainId]
  const ghcContractOld = useTokenContract(ghcTokenOld?.address, true)

  const ghcTokenNew = GHC_NEW_TOKEN_BY_CHAIN[chainId]
  const ghcContractNew = useTokenContract(ghcTokenNew?.address, true)

  useEffect(() => {
    const loadData = async () => {
      if (!account) return
      if (![97, 56].includes(chainId)) {
        setChainIdError(true)
        return
      }

      try {
        // Get balance and allowance of old token
        const _ghcOldBalance = await ghcContractOld?.callStatic.balanceOf(
          account
        )

        setGhcOldBalance(new BigNumberJS(_ghcOldBalance.toString()))

        const ghcOldAllowance = await ghcContractOld?.callStatic.allowance(
          account,
          migrationContract?.address
        )

        setBridgeEnabled(
          ghcOldAllowance.toString() === ethers.constants.MaxUint256.toString()
        )

        // // Get balance of new token
        const _ghcNewBalance = await ghcContractNew?.callStatic.balanceOf(
          account
        )
        setGhcNewBalance(_ghcNewBalance.toString())
      } catch (e) {
        console.log('Error', e)
      }
    }
    loadData()
  }, [
    account,
    chainId,
    ghcContractOld,
    ghcContractNew,
    renderKey,
    migrationContract,
  ])

  const handleApprove = async () => {
    try {
      const maxUint256 = ethers.constants.MaxUint256
      setPendingTx(true)
      const isEnabled = await ghcContractOld.approve(
        migrationContract?.address,
        maxUint256
      )
      await isEnabled.wait(1)
      toastSuccess('Contract Enabled', 'You can now migrate')
    } catch (e) {
      console.log('ERROR', e)
      toastError('ERROR', 'Please confirm this transaction in your wallet')
    }
    setPendingTx(false)
    setRenderKey(Math.random() * 10)
  }

  const handleMigrate = async () => {
    setPendingTx(true)
    try {
      const tx = await migrationContract.claimTokenTrade()
      const message = await tx.wait(1)
      toastSuccess('Migration Successful')
    } catch (e) {
      toastError('Error', 'Please try again. Confirm the transaction!')
      console.log('e', e)
    }
    setPendingTx(false)
    setRenderKey(Math.random() * 10)
  }

  const ghcOldBalanceString = getBalanceString(ghcOldBalance)

  return (
    <Container>
      <AppBody>
        <Wrapper>
          <PageHeader
            title="Automatic Migration"
            hideControls
            description="Upgrade your GHC tokens to the latest version to enable trading"
          />
          <CardBody>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <img
                alt="old Ghc logo"
                style={{ width: 50, height: 50 }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle-old.png`}
              />
              <Text margin={2} textAlign="center">
                <b>{ghcOldBalanceString}</b>
                <br />
                <a href={`https://bscscan.com/token/${ghcTokenOld?.address}`}>
                  Old GHC Balance
                </a>
              </Text>
              <br />
              <div>
                <FontAwesomeIcon size="3x" icon={faArrowDown} />
              </div>
              <br />
              <br />
              <img
                alt="new Ghc logo"
                style={{ width: 50, height: 50 }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle.png`}
              />
              <Text margin={2} textAlign="center">
                <b>
                  {Number(ghcOldBalance) === 0
                    ? getBalanceString(ghcNewBalance)
                    : getBalanceString(ghcOldBalance / 2)}
                </b>
                <br />
                <a href={`https://bscscan.com/token/${ghcTokenNew?.address}`}>
                  New GHC Balance
                </a>
              </Text>
            </div>
            <br />
            <Text fontSize={10} fontStyle="italic" textAlign="center">
              Note: You will recieve 1 new GHC token for every 2 old GHC tokens
              migrated.
            </Text>

            <AutoColumn gap="md" />
            <BottomGrouping>
              {account ? (
                ghcOldBalanceString === '0.00' ? (
                  <Btn width="100%" disabled onClick={handleMigrate}>
                    No old GHC to Migrate
                  </Btn>
                ) : bridgeEnabled ? (
                  <Btn
                    width="100%"
                    disabled={pendingTx || ghcOldBalance === '0'}
                    onClick={handleMigrate}
                  >
                    {pendingTx ? 'Pending Confirmation' : 'Migrate GHC'}
                  </Btn>
                ) : (
                  <Btn
                    width="100%"
                    disabled={pendingTx || chainIdError}
                    onClick={handleApprove}
                  >
                    {pendingTx ? 'Pending Confirmation' : 'Activate Migrator'}
                  </Btn>
                )
              ) : (
                <Btn disabled>Connect to wallet first</Btn>
              )}
            </BottomGrouping>
            <ErrorText>
              <br />
              {chainIdError && 'Please connect your wallet to BSC / BNB Chain'}
            </ErrorText>
            <br />
          </CardBody>
        </Wrapper>
      </AppBody>
    </Container>
  )
}

export default BridgePage
