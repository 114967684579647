import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import GetPrice from 'utils/getPrice'
import { formatNumber } from 'utils/formatBalance'
import './style.css'
import Background from './background.webp'

const StarsBG = styled.img`
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* or object-fit: contain; */
  top: 0;
  left: 0;
`

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`

const Logo = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
`

const TitleText = styled.div`
  padding-top: 5px;
  font-size: 32px;
  flex: 1;
  text-align: center;
  color: white;
  @media only screen and (max-width: 780px) {
    margin: 0px 0px 10px 0px;
  }
`

const TitleTextBold = styled.div`
  font-weight: 600;
  display: contents;
  // filter: drop-shadow(30px 10px 4px #4444dd);
  filter: drop-shadow(3px 1px 1px white);
  box-shadow: ${({ theme }) => theme.colors.primary} 0px 0px 1.5rem,
    ${({ theme }) => theme.colors.primary} 0px 0px 1rem 0px inset;
`

const ConnectBtnContainer = styled.div`
  flex: 1;
  text-align: right;
`

const ConnectBtn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.textSubtle};
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: rgba(1, 1, 1, 0.4);
  border-top: 1px solid darkgrey;
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
`

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const location = useLocation()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account || undefined
  )
  const [mainTokenPrice, setMainTokenPrice] = useState('')
  const { pathname } = location
  const { children } = props

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPrice()
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  async function getPrice() {
    try {
      const rewardTokenClass = new GetPrice()
      await rewardTokenClass.init('GHC')
      const {
        pairPriceA: esbbPrice,
        pairPriceB: bbprice,
        price: esprice,
      } = await rewardTokenClass.getLatestPrice()
      setMainTokenPrice(formatNumber(esprice.toNumber(), 12, 12))
    } catch (e) {
      console.log('pe 01', e)
    }
  }

  return (
    <div className="egg-menu-container">
      {/* title bar */}
      <div className="title-bar">
        <div className="container content">
          {/* logo */}
          <LogoContainer>
            <Logo
              src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle.png`}
            />
          </LogoContainer>
          {/* title */}
          <TitleText className="title-text">
            <TitleTextBold>Galaxy Heroes</TitleTextBold> Migrator
          </TitleText>
          {/* connect */}
          <ConnectBtnContainer>
            <ConnectBtn
              onClick={account ? onPresentAccountModal : onPresentConnectModal}
            >
              {account ? 'Connected' : 'Connect to a wallet'}
            </ConnectBtn>
          </ConnectBtnContainer>
        </div>
      </div>

      {/* content */}
      <div className="main-content">
        <StarsBG src={Background} />
        {children}
      </div>

      {/* footer */}
      <Footer>
        <FooterContent
          className="container"
          style={{ justifyContent: 'space-between', opacity: 0.8 }}
        >
          <div>
            This dApp was built with ❤ by the wizards at{' '}
            <a
              style={{ color: 'light-blue' }}
              target="_blank"
              rel="noreferrer"
              href="https://highstack.co"
            >
              Highstack.co
            </a>
          </div>
          <div style={{ textAlign: 'right' }}>
            *Galaxy Heroes makes no promises and is not responsible for any
            losses or errors. Use at your own risk.
          </div>
        </FooterContent>
      </Footer>
    </div>
  )
}

export default Menu
