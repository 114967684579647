import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import FloatingStarsBg from '../components/FloatingStarsBg'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'

import Migrate from './Migrate'
import { RedirectPathToHomeOnly } from './Home/redirects'

import EggMenu from '../components/EggMenu'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  margin-bottom: 64px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const fileId = 6

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper style={{ overflow: 'hidden' }}>
          <EggMenu>
            <BodyWrapper>
              <Popups />
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path="/migrate" component={Migrate} />
                  <Route component={RedirectPathToHomeOnly} />
                </Switch>
              </Web3ReactManager>
            </BodyWrapper>
          </EggMenu>
          
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
